
import { Component, Vue, Prop } from "vue-property-decorator";
import CategoryService from "@/services/category_service";
import { EventBus } from "@/events/index";
import { LoaderComponent } from "node_modules/vue-loading-overlay/types/loading";

@Component
export default class ExportProducts extends Vue {
  @Prop() protected id!: number;
  @Prop() protected display_name!: string;
  @Prop() protected exportType!: string;
  protected categoryService = new CategoryService();
protected loader: LoaderComponent | null = null;

  protected showSpinner(): LoaderComponent {
    const loader = this.$loading.show(
      {
        isFullPage: true,
        canCancel: false,
      },
      { before: this.$createElement("h1", "Downloading...") }
    );
    return loader;
  }

    protected async downloadCsv() {
    this.loader = this.showSpinner();
    const date = new Date().toLocaleDateString('en-US').replaceAll('/', '_')
    const fileName = this.display_name.toLowerCase().replaceAll(' ', '_');
    try {
      const csv = await this.categoryService.getProductCsv(this.id, this.exportType);
      this.loader.hide();
      const hiddenElement = document.createElement('a');
      const universalBOM = "\uFEFF";
      hiddenElement.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = `${this.id}-${this.exportType}-${fileName}-${date}.csv`;
      hiddenElement.click();
    } catch (err) {
        EventBus.$emit("showError", err.message);
        this.loader.hide();
    }
  }
  
}
